import React, { useEffect, useReducer, useMemo } from "react";
import Layout from "../../components/Layout";
import {
  Map as LeafletMap,
  TileLayer,
  Marker,
  Popup,
  Circle,
  LayerGroup
} from "react-leaflet";
import { latLng, Icon } from "leaflet";
import { ActionHandler, makeReducer } from "../../reducers";
import useIsSsr from "../../hooks/useIsSsr";
import TubbyIconPng from "../../img/tubby.png";
import styled from "styled-components";
import TimeAgo from "react-timeago";
import { WheresTubbyResponse } from "../../types/WheresTubby";
import { Helmet } from "react-helmet";
const ICON_WIDTH = 214;
const ICON_HEIGHT = 98;

export interface TubbyState {
  wheresTubbyResponse: WheresTubbyResponse | undefined;
  mapCenter: { lat: number; lng: number };
  zoom: number;
  hasPanned: boolean;
  isWanHistoryShown: boolean;
}

const INITIAL_STATE: TubbyState = {
  wheresTubbyResponse: undefined,
  mapCenter: { lat: 39.8333333, lng: -98.585522 },
  zoom: 18,
  hasPanned: false,
  isWanHistoryShown: false
};

type TubbyStateActionHandler<PayloadType = undefined> = ActionHandler<
  TubbyState,
  PayloadType
>;

type ActionHandlerTypes = {
  "set-tubby-response": TubbyStateActionHandler<WheresTubbyResponse>;
  "center-on-tubby": TubbyStateActionHandler;
  "handle-zoom-end": TubbyStateActionHandler<{ zoom: number }>;
  "handle-pan-end": TubbyStateActionHandler<{
    mapCenter: { lat: number; lng: number };
  }>;
  "show-wan-history": TubbyStateActionHandler;
};

const ActionHandlers: ActionHandlerTypes = {
  "set-tubby-response": (state, payload) => {
    const newState = { ...state, wheresTubbyResponse: payload };
    return state.hasPanned
      ? newState
      : ActionHandlers["center-on-tubby"](newState, undefined);
  },

  "center-on-tubby": state =>
    state.wheresTubbyResponse
      ? {
          ...state,
          mapCenter: latLng(
            state.wheresTubbyResponse.la,
            state.wheresTubbyResponse.lo
          )
        }
      : state,

  "handle-zoom-end": (state, payload) => ({ ...state, ...payload }),
  "handle-pan-end": (state, payload) => ({
    ...state,
    ...payload,
    hasPanned:
      !!state.wheresTubbyResponse &&
      (Math.abs(payload.mapCenter.lat - state.wheresTubbyResponse.la) >
        0.00001 ||
        Math.abs(payload.mapCenter.lng - state.wheresTubbyResponse.lo) >
          0.00001)
  }),
  "show-wan-history": state => ({
    ...state,
    isWanHistoryShown: true
  })
};

const ICON_SCALES = new Map([
  [19, 1],
  [18, 0.75],
  [17, 0.5],
  [16, 0.25]
]);

const reducer = makeReducer<TubbyState, ActionHandlerTypes>(ActionHandlers);

const MapContainer = styled.div``;
const InfoBox = styled.div``;

const Container = styled.div`
  flex: 1 1 100%;
  max-height: 100%;

  display: flex;
  flex-direction: column;

  ${MapContainer} {
    flex: 1 1 100%;

    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-direction: column;

    .leaflet-container {
      flex: 1 1 100%;
    }
  }

  ${InfoBox} {
    flex: 0 0 auto;
    padding: 20px;
    border-top: 2px solid darkgray;
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const signalToBars = (signalDb: number): 1 | 2 | 3 | 4 => {
  // https://brinkcase.com/blogs/brinkcase/behind-the-bars
  if (signalDb <= -115) return 1;
  else if (signalDb <= -105) return 2;
  else if (signalDb <= -95) return 3;
  else return 4;
};

const BAR_COLORS = {
  1: "red",
  2: "yellow",
  3: "blue",
  4: "green"
};

const WheresTubby: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    const loadTubbyPosition = () => {
      fetch("/.netlify/functions/wheres-tubby")
        .then(response => response.json())
        .then(json => {
          console.log("Response", json);
          dispatch({ type: "set-tubby-response", payload: json });
        });
    };
    loadTubbyPosition();
    const interval = setInterval(loadTubbyPosition, 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  const isSsr = useIsSsr();

  const icon = useMemo(() => {
    if (isSsr) return null;

    const scale = ICON_SCALES.get(state.zoom) || 1;
    return new Icon({
      iconUrl: TubbyIconPng,
      iconSize: [ICON_WIDTH * scale, ICON_HEIGHT * scale],
      iconAnchor: [(ICON_WIDTH * scale) / 2, ICON_HEIGHT * scale]
    });
  }, [isSsr, state.zoom]);

  const mostRecentWan =
    (state.wheresTubbyResponse &&
      state.wheresTubbyResponse.wanHistory &&
      state.wheresTubbyResponse.wanHistory[
        state.wheresTubbyResponse.wanHistory.length - 1
      ]) ||
    undefined;

  const tubbyPosition = state.wheresTubbyResponse
    ? latLng(state.wheresTubbyResponse.la, state.wheresTubbyResponse.lo)
    : undefined;

  return (
    <Layout noMargin>
      <Helmet>
        <title>Where's Tubby</title>
      </Helmet>
      <Container>
        <MapContainer>
          {isSsr || (
            <LeafletMap
              center={state.mapCenter}
              zoom={state.zoom}
              style={{ height: "100%" }}
              maxZoom={19}
              onZoomEnd={(e: any) =>
                e.target &&
                dispatch({
                  type: "handle-zoom-end",
                  payload: { zoom: e.target.getZoom() }
                })
              }
              onMoveEnd={(e: any) =>
                e.target &&
                dispatch({
                  type: "handle-pan-end",
                  payload: { mapCenter: e.target.getCenter() }
                })
              }
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {tubbyPosition &&
                (icon &&
                state.zoom >= Math.min.apply(null, [...ICON_SCALES.keys()]) ? (
                  <Marker
                    key={"WithTubby"}
                    position={tubbyPosition}
                    icon={icon}
                  />
                ) : (
                  <Marker position={tubbyPosition} />
                ))}
              {state.wheresTubbyResponse &&
                state.wheresTubbyResponse.wanHistory &&
                state.isWanHistoryShown && (
                  <LayerGroup>
                    {state.wheresTubbyResponse.wanHistory.map(_ => (
                      <Circle
                        key={_.ts}
                        center={[_.la, _.lo]}
                        radius={5}
                        color={BAR_COLORS[signalToBars(_.signal)] || "black"}
                      >
                        <Popup>
                          Date: <TimeAgo date={_.ts} live />
                          <br />
                          Carrier: {_.carrier_name}
                          <br />
                          Signal type: {_.type}
                          <br />
                          Signal: {_.signal}db
                        </Popup>
                      </Circle>
                    ))}
                  </LayerGroup>
                )}
            </LeafletMap>
          )}
        </MapContainer>
        <InfoBox>
          <strong>Location: </strong>
          {state.wheresTubbyResponse?.ts && (
            <TimeAgo date={state.wheresTubbyResponse.ts} live />
          )}
          <p>
            <a
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&destination=${state.wheresTubbyResponse?.la},${state.wheresTubbyResponse?.lo}`}
            >
              <NoWrap>Latitude: {state.wheresTubbyResponse?.la} </NoWrap>
              <NoWrap>Longitude: {state.wheresTubbyResponse?.lo}</NoWrap>
            </a>
            &nbsp;
            {state.hasPanned && (
              <button
                onClick={() =>
                  dispatch({ type: "center-on-tubby", payload: undefined })
                }
              >
                Center
              </button>
            )}
          </p>

          <strong>Cell info: </strong>
          {mostRecentWan && (
            <>
              <TimeAgo date={mostRecentWan.ts} live />
              <p>
                {mostRecentWan.carrier_name} ({mostRecentWan.type}){" "}
                {mostRecentWan.signal}db
              </p>
            </>
          )}
          {(state.wheresTubbyResponse?.wanHistory?.length || 0) > 0 &&
            !state.isWanHistoryShown && (
              <button
                onClick={() =>
                  dispatch({ type: "show-wan-history", payload: undefined })
                }
              >
                Show history
              </button>
            )}
        </InfoBox>
      </Container>
    </Layout>
  );
};

export default WheresTubby;
