export type ActionHandler<StateType, PayloadType = undefined> = (
  state: StateType,
  payload: PayloadType
) => StateType;

export type ReducerFunctions<StateType> = {
  [actionName: string]: ActionHandler<StateType, any>;
};

// Extracts the second argument (payload type) of an action handler
export type ActionHandlerPayloadType<
  StateType,
  A extends ActionHandler<StateType, any>
> = A extends (...args: [StateType, infer PayloadType]) => StateType
  ? PayloadType
  : never;

// type Test = ActionHandlerPayloadType<number, ActionHandler<number, string>> // string

export type IndexedActionTypes<
  StateType,
  ReducerFns extends ReducerFunctions<StateType>
> = {
  [Type in keyof ReducerFns]: {
    type: Type;
    payload: ActionHandlerPayloadType<StateType, ReducerFns[Type]>;
  };
};

type ActionTypes<
  StateType,
  ReducerFns extends ReducerFunctions<StateType>
> = IndexedActionTypes<StateType, ReducerFns>[keyof IndexedActionTypes<
  StateType,
  ReducerFns
>];

export type Reducer<
  StateType,
  ReducerFunctionsType extends ReducerFunctions<StateType>
> = (
  state: StateType,
  action: ActionTypes<StateType, ReducerFunctionsType>
) => StateType;

export function makeReducer<S, F extends ReducerFunctions<S>>(
  functions: F
): Reducer<S, F> {
  return (state: S, action: ActionTypes<S, F>) => {
    const newState = functions[action.type](state, action.payload);
    console.debug("Dispatching action", action, state, newState);
    return newState;
  };
}
